var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app-bar',{attrs:{"id":"app-bar","absolute":"","app":"","color":"transparent","flat":"","height":"75"}},[_c('v-btn',{staticClass:"mr-3",attrs:{"elevation":"1","fab":"","small":""},on:{"click":function($event){return _vm.setDrawer(!_vm.drawer)}}},[(_vm.value)?_c('v-icon',[_vm._v(" mdi-view-quilt ")]):_c('v-icon',[_vm._v(" mdi-menu ")])],1),_c('v-toolbar-title',{staticClass:"hidden-sm-and-down font-weight-light",domProps:{"textContent":_vm._s(_vm.$route.meta.title)}}),_c('v-spacer'),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-width":300,"bottom":"","right":"","offset-y":"","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"icon":"","text":""}},on),[_c('v-icon',{staticStyle:{"font-size":"24px"},attrs:{"color":"black"}},[_vm._v(" mdi-account ")])],1)]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-card',{staticStyle:{"margin":"0px"}},[_c('v-list',[_c('v-list-item',[_c('v-avatar',[_c('v-icon',[_vm._v("mdi-account")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$user.get().name))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.$user.get().email))])],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-row',[_c('v-col',{attrs:{"cols":"10"}},[(
                _vm.$user.get().role !== 'admin' &&
                _vm.$user.get().role !== 'consultoria' &&
                _vm.$user.get().CodPessoa != null
              )?_c('v-btn',{attrs:{"color":"blue darken-4","text":""},on:{"click":() => {
                  _vm.path = `/${_vm.$user.get().role}/perfil`
                  if (this.$route.path !== _vm.path) this.$router.push(_vm.path)
                }}},[_vm._v(" Ver perfil "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v(" mdi-card-account-details ")])],1):_vm._e(),_c('v-btn',{attrs:{"text":""},on:{"click":() =>
                  this.$router.push({
                    path: `/${_vm.$user.get().role}/trocar-senha`,
                  })}},[_vm._v(" Trocar minha senha "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v(" mdi-lock ")])],1)],1),(_vm.multpleRoles)?_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.dialog = true}}},[_vm._v(" Mudar Perfil "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v(" mdi-account-switch ")])],1)],1):_vm._e(),_c('v-col',{attrs:{"cols":"3"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"error"},on:{"click":_vm.logout}},[_vm._v(" sair "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v(" mdi-logout ")])],1)],1)],1)],1)],1)],1),[_c('div',{staticClass:"text-center"},[_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h3 grey lighten-2"},[_vm._v(" Mudança de Perfil ")]),_c('v-card-text',{attrs:{"align":"center"}},[_vm._v(" Você está atualmente acessando o sistema como "+_vm._s(_vm.capitalizeFirstLetter(_vm.$user.get().role))+". "),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","sm":"6"}},[_c('v-select',{attrs:{"items":_vm.items,"label":"Escolha seu perfil"},model:{value:(_vm.selectedRole),callback:function ($$v) {_vm.selectedRole=$$v},expression:"selectedRole"}})],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.updateRoles}},[_vm._v(" Salvar ")])],1)],1)],1)],1)]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }