<template>
  <v-app-bar id="app-bar" absolute app color="transparent" flat height="75">
    <v-btn class="mr-3" elevation="1" fab small @click="setDrawer(!drawer)">
      <v-icon v-if="value"> mdi-view-quilt </v-icon>

      <v-icon v-else> mdi-menu </v-icon>
    </v-btn>

    <v-toolbar-title
      class="hidden-sm-and-down font-weight-light"
      v-text="$route.meta.title"
    />

    <v-spacer />

    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-width="300"
      bottom
      right
      offset-y
      transition="slide-y-transition"
    >
      <template v-slot:activator="{ on }">
        <v-btn icon text v-on="on">
          <v-icon color="black" style="font-size: 24px"> mdi-account </v-icon>
        </v-btn>
      </template>
      <v-card style="margin: 0px">
        <v-list>
          <v-list-item>
            <v-avatar>
              <v-icon>mdi-account</v-icon>
            </v-avatar>

            <v-list-item-content>
              <v-list-item-title>{{ $user.get().name }}</v-list-item-title>
              <v-list-item-subtitle>{{
                $user.get().email
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider />
        <v-card-actions>
          <v-row>
            <v-col cols="10">
              <v-btn
                v-if="
                  $user.get().role !== 'admin' &&
                  $user.get().role !== 'consultoria' &&
                  $user.get().CodPessoa != null
                "
                color="blue darken-4"
                text
                @click="
                  () => {
                    path = `/${$user.get().role}/perfil`
                    if (this.$route.path !== path) this.$router.push(path)
                  }
                "
              >
                Ver perfil
                <v-icon right dark> mdi-card-account-details </v-icon>
              </v-btn>
              <v-btn
                text
                @click="
                  () =>
                    this.$router.push({
                      path: `/${$user.get().role}/trocar-senha`,
                    })
                "
              >
                Trocar minha senha
                <v-icon right dark> mdi-lock </v-icon>
              </v-btn>
            </v-col>
            <v-col v-if="multpleRoles" cols="6">
              <v-btn text @click="dialog = true">
                Mudar Perfil
                <v-icon right dark> mdi-account-switch </v-icon>
              </v-btn>
            </v-col>
            <v-col cols="3">
              <v-spacer />
              <v-btn text color="error" @click="logout">
                sair
                <v-icon right dark> mdi-logout </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-menu>

    <template>
      <div class="text-center">
        <v-dialog v-model="dialog" width="500">
          <v-card>
            <v-card-title class="text-h3 grey lighten-2">
              Mudança de Perfil
            </v-card-title>

            <v-card-text align="center">
              Você está atualmente acessando o sistema como
              {{ capitalizeFirstLetter($user.get().role) }}.

              <v-col class="d-flex" cols="12" sm="6">
                <v-select
                  v-model="selectedRole"
                  :items="items"
                  label="Escolha seu perfil"
                ></v-select>
              </v-col>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red" text @click="dialog = false"> Cancelar </v-btn>
              <v-btn color="primary" text @click="updateRoles"> Salvar </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </template>
  </v-app-bar>
</template>

<script>
import { mapMutations, mapState } from 'vuex'

export default {
  name: 'DashboardCoreAppBar',

  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    dialog: false,
    selectedRole: '',
    multpleRoles: false,
    items: [],
    menu: false,
  }),

  computed: {
    ...mapState(['drawer']),
  },

  mounted() {
    this.$root.$on('profileUpdate', name => {
      this.$user.get().name = name
      this.updateRoles()
    })
  },

  created() {
    this.existsMultiplesRoles()
    this.loadRoles()
  },

  methods: {
    ...mapMutations({
      setDrawer: 'SET_DRAWER',
    }),

    logout() {
      localStorage.clear()
      location.reload()
    },

    existsMultiplesRoles() {
      const roles = JSON.parse(localStorage.getItem('roles'))

      if (roles.length > 1) {
        this.multpleRoles = true
      }
    },

    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },

    loadRoles() {
      const roles = JSON.parse(localStorage.getItem('roles'))
      this.items = roles.map(r => this.capitalizeFirstLetter(r.slug))

      this.selectedRole = this.capitalizeFirstLetter(this.$user.get().role)
    },

    updateRoles() {
      const role = this.selectedRole.toLowerCase()
      if (role && role != '' && role.length > 0) {
        const user = JSON.parse(localStorage.getItem('user'))

        user.role = role

        localStorage.setItem('user', JSON.stringify(user))
        this.$user.set(user)

        this.dialog = false

        this.$router.go()
      }
    },
  },
}
</script>
